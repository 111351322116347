import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { Button, Flex } from 'rebass/styled-components'

const SaveButton = ({ onSubmit, saveReady, isSaving, isSidebarControl = false }) => {
    const [state, setState] = useState({ color: 'greyMedium', pointerEvents: 'auto', text: 'Save', init: false })
    let timeoutId = null
    useEffect(() => {
        if (saveReady) {
            setState({ ...state, color: 'red', pointerEvents: 'auto', text: 'Ready to Save', init: true })
        }
        if (isSaving) {
            setState({ ...state, color: 'orange', pointerEvents: 'none', text: 'Saving' })
        }
        if (!isSaving && !saveReady && state.init) {
            setState({ ...state, color: 'green', pointerEvents: 'auto', text: 'Saved Successfully', init: false })
            timeoutId = setTimeout(() => {
                setState({ ...state, color: 'greyMedium', text: 'Save' })
            }, 2000)
        }

        return(() => clearTimeout(timeoutId))
    }, [saveReady, isSaving])
    return (
        <Flex alignItems='center' justifyContent='center' flexDirection='column'>
            <Button bg={state.color} onClick={onSubmit} sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                borderColor: state.color,
                pointerEvents: state.pointerEvents,
                padding: isSidebarControl ? '24px 12px' : '12px 24px',
                width: isSidebarControl ? '58px' : 'auto',
                borderRadius: isSidebarControl ? '4px 0 0 4px' : null,
                boxShadow: isSidebarControl ? '0 4px 4px rgb(0 0 0 / 20%)' : null,
                marginTop: isSidebarControl ? '12px' : null,
            }}>
                {
                    isSidebarControl 
                        ? <span style={{ writingMode: 'vertical-lr', transform: 'rotate(180deg)' }}>{state.text}</span>
                        : <span>{state.text}</span>
                }
            </Button>
            {
                // saveMessage.visible ? <Box pt='xxs'><Text color='green'>Save Successful!</Text></Box> : null
            }
        </Flex>
    )
}
function mapStateToProps(state) {
    return {
        saveReady: state.Savehandling.isSaveReady,
        isSaving: state.Savehandling.isSaving,
    }
}

export default connect(mapStateToProps)(SaveButton)
