import React, { useState, useEffect } from 'react'
import Select from 'react-select'
import { onSnapshot, collection, query, orderBy, where } from 'firebase/firestore'
import { db } from '../../system/firebase/index'

const UserSelect = ({ dispatch, placeholder, onSelectChange, item, specialist = false, styles = false, isClearable = false }) => {
    const [dropdown, setDropdown] = useState([])
    const [items, setItems] = useState([])
    const [currentItem, setItem] = useState(false)

    useEffect(() => {
        let isCancelled = false
        let q = false
        if(specialist) {
            q = query(collection(db, 'users'), where('specialist', '==', true), orderBy('last_name', 'asc'))
        } else {
            q = query(collection(db, 'users'), orderBy('last_name', 'asc'))
        }
        onSnapshot(q, (snapshot) => {
            if (!isCancelled) {
                const output = []
                snapshot.forEach((doc) => {
                    output.push(doc.data())
                })
                setItems(output)

            }
        })
        return () => {
            isCancelled = true
        }
    }, [specialist])
    
    useEffect(() => {
        const options = []
        if (items) {
            Object.entries(items).map(([key, val]) => {
                options.push({ value: val.uid, label: val.first_name + ' ' + val.last_name })
                return true
            })
            setDropdown(options)
        }
    }, [items])

    useEffect(() => {
        setItem(dropdown.filter((option) => option.value === item )[0])
    }, [dropdown, item])

    const handleSelectChange = (newItem) => {
        setItem(newItem)
        onSelectChange(newItem)
    }

    return <Select
        placeholder={placeholder}
        styles={styles}
        options={dropdown}
        value={currentItem}
        isClearable={isClearable}
        onChange={(newItem) => handleSelectChange(newItem)}
    />
}

export default UserSelect