import React, { useState, useEffect } from 'react'
import Select from 'react-select'

const SimpleSelect = ({ dispatch, placeholder, onSelectChange, options, item, styles = false }) => {
    const [dropdown, setDropdown] = useState([])
    const [currentItem, setItem] = useState(false)
    
    useEffect(() => {
        if (options) {
            setDropdown(options)
        }
    }, [options])

    useEffect(() => {
        setItem(dropdown.filter((option) => option.value === item )[0])
    }, [dropdown, item])

    const handleSelectChange = (newItem) => {
        setItem(newItem)
        onSelectChange(newItem)
    }

    return <Select
        placeholder={placeholder}
        styles={styles}
        options={dropdown}
        value={currentItem}
        onChange={(newItem) => handleSelectChange(newItem)}
    />
}

export default SimpleSelect
