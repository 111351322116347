import React, { useState } from 'react'
import { Box, Flex } from 'rebass/styled-components'
import { Input } from '@rebass/forms/styled-components'
import * as algoliasearch from 'algoliasearch'
import LeadSearchResult from './LeadSearchResult'
import Loading from '../general/Loading'
 
const LeadSearchBar = ({ resultSelectCallback }) => {
    const [searchTerm, setSearchTerm] = useState("")
    const [focused, hasFocus] = useState(false)
    const [loading, isLoading] = useState(false)
    const [results, setResults] = useState(false)

    let timeOutId = null
    const doSearch = ( term ) => {
        clearTimeout(timeOutId)

        if(term.trim() === "") {
            isLoading(false)
            setResults(false)
            return;
        }

        const algolia = algoliasearch(process.env.GATSBY_ALGOLIA_APP_ID, process.env.GATSBY_ALGOLIA_API_KEY)
        const index = algolia.initIndex(process.env.GATSBY_ALGOLIA_INDICE)
        const filter = { hitsPerPage: 1000, typoTolerance: 'min' }
        timeOutId = setTimeout(() => {
            index.search(term, filter).then(({ hits }) => {
                isLoading(false)
                setResults(hits)
            });
        }, 500)
    }

    const handleChange = ( e ) => {
        setSearchTerm(e.target.value)

        isLoading(true)
        doSearch(e.target.value)
    }

    const handleFocus = () => {
        hasFocus(true)
    }

    const handleBlur = () => {
        hasFocus(false)
    }

    const handleResultSelect = (result) => {
        isLoading(false)
        setResults(false)
        setSearchTerm("")

        resultSelectCallback(result)
    }

    return (
        <Box flexGrow='1'>
            <Flex justifyContent="center" onFocus={handleFocus} onBlur={handleBlur} sx={{
                position: 'relative',
                zIndex: '10',
            }}>
                <Input
                    id='search'
                    name='search'
                    type='text'
                    placeholder='Search...'
                    variant='searchInput'
                    value={ searchTerm }
                    onChange={ handleChange }
                />
                {
                    loading || results ?
                        <Box display={focused ? 'initial' : 'none'} bg='charcoal' color='whiteOff' maxHeight='312px' width='100%' sx={{
                            position: 'absolute',
                            top: '43px',
                            left: '0',
                            overflowY: 'scroll',
                        }}>
                            {
                                loading ?
                                    <Flex justifyContent='center' py='xs' px='xxs' width='100%'><Loading color='white' size='20px' /></Flex> :
                                    Object.entries(results).map(([key, doc]) => {
                                        return <LeadSearchResult key={key} result={doc} handleSelect={handleResultSelect} />
                                    })
                            }
                        </Box> :
                        null
                }
            </Flex>
        </Box>
    )
}

export default LeadSearchBar
