import React, { forwardRef } from 'react'
import { Box, Flex } from 'rebass/styled-components'
import { Input } from '@rebass/forms/styled-components'
import styled from 'styled-components'
import TableInputError from '../tables/TableInputError'

const Container = styled(Flex)`
    position: relative;
    width: ${(props) => props.width};
    .contentEditableInput {
        position: absolute;
        left: 0;
        bottom: 4px;
        pointer-events: none;
    }
`
const Message = styled(Box)`
    position: absolute;
    left: -2px;
    bottom: -10px;
`

const EditableField = (props, ref) => {
    const { errors, errorMessage, name, width, ...rest } = props
    return (
        <Container alignItems="center" justifyContent="flex-start" flexDirection="column" width={width || '100%'}>
            <Input ref={ref} name={name} {...rest} />
            <Box className="contentEditableInput">...</Box>
            {(errors && errors[name] && errorMessage) && <Message><TableInputError message={errorMessage} /></Message>}
        </Container>
    )
}

export default forwardRef(EditableField)
