import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { Box } from 'rebass/styled-components'
import CreatableSelect from 'react-select/creatable'
import makeAnimated from 'react-select/animated'
import { addCollectionCharacteristic } from '../../models/CollectionCharacteristics'
import { setPageCharacteristics } from '../../system/redux/reducers/pagebuilder'
import { saveToggle } from '../../system/redux/reducers/savehandling'
import { generateSlug } from '../../helpers/Functions'
import { onSnapshot, collection, where, query, orderBy } from 'firebase/firestore'
import { db } from '../../system/firebase/index'

const MakesSelect = ({ dispatch, placeholder, auth, pageMake, active=true }) => {
    const animatedComponents = makeAnimated();
    const [details, setDetails] = useState({ doc: '', details: false, options: [], selected: []})
    const [activeState, setState] = useState(active)
    const [makes, setMakes] = useState(false)

    useEffect(() => {
        let isCancelled = false
        const q = query(collection(db, 'collectionCharacteristics'), where('slug', '==', 'makes'))
        onSnapshot(q, (snapshot) => {
            if (!isCancelled) {
                const output = []
                snapshot.forEach((doc) => {
                    const saved = doc.data()
                    saved.id = doc.id
                    output.push(saved)
                })
                setMakes(output)
            }
        })

        return () => {
            isCancelled = true;
        }
    }, [])

    useEffect(() => {
        let isCancelled = false
        if (makes) {
            Object.entries(makes).map(([key, doc]) => {
                const q = query(collection(db, 'collectionCharacteristics', doc.id, 'list'), orderBy('title'))
                onSnapshot(q, (snapshot) => {
                    if (!isCancelled) {
                        const options = []
                        const selected = []
                        snapshot.forEach((doc) => {
                            const val = doc.data()
                            val.id = doc.id
                            options.push({ value: val.id, label: val.title })
                            if (pageMake && pageMake === val.id) {
                                selected.push({ value: val.id, label: val.title })
                            }
                        })
                        setDetails({ doc: doc.id, details: true, options, selected })
                    }
                })
            })
        }

        return () => {
            isCancelled = true;
        }
    }, [pageMake, makes])

    useEffect(() => {
        setState(active)
    }, [active])

    const createOption = (label, slug) => ({
        label,
        value: slug,
    })
    const handleCreate = async (inputValue) => {
        const slug = generateSlug(inputValue)
        const data = {
            branch: details.doc,
            type: 'auto',
            content: {
                added_by: auth.uid,
                title: inputValue,
                slug,
                edited_by: auth.uid,
            },
        }
        const newMake = await addCollectionCharacteristic(data)
        const newOption = createOption(inputValue, newMake.data)
        onSelectChange('make', newOption)
    }

    const onSelectChange = (name, event) => {
        const detailsData = JSON.stringify({ [name]: event.value })
        dispatch(setPageCharacteristics(detailsData))
        dispatch(saveToggle(true))
    }

    let output = 'None'
    if (Object.keys(details.selected).length >= 1 && details.selected[0].label) {
        output = details.selected[0].label
    }
    return (
        activeState ?
            <CreatableSelect
                placeholder={placeholder}
                isClearable={false}
                closeMenuOnSelect={true}
                components={animatedComponents}
                onChange={(newValue) => onSelectChange('make', newValue)}
                onCreateOption={handleCreate}
                options={details.options}
                value={details.selected}
            /> :
            (<Box>
                {output}
            </Box>)

    )
}

function mapStateToProps(state) {
    return {
        auth: state.Auth.userVerified,
        pageMake: state.Pagebuilder.extras && state.Pagebuilder.extras.characteristics && state.Pagebuilder.extras.characteristics.make,
    }
}

export default connect(mapStateToProps)(MakesSelect)
