import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import Select from 'react-select'
import { onSnapshot, collection, query, orderBy } from 'firebase/firestore'
import { db } from '../../system/firebase/index'
import { getName } from '../../helpers/Clients'

const ClientSelect = ({ placeholder, onInputChange, selectedValue, styles = false, isClearable = false }) => {
    const [selected, setSelected] = useState(selectedValue)
    const [details, setDetails] = useState({ details: false, options: []})

    useEffect(() => {
        let isCancelled = false
        const q = query(collection(db, 'clients'), orderBy('last_name', 'asc'))
        onSnapshot(q, (snapshot) => {
            if (!isCancelled) {
                const options = []
                snapshot.forEach((doc) => {
                    const val = doc.data()
                    val.id = doc.id
                    const label = getName(val.first_name, val.last_name, val.company)

                    if (label !== '') {
                        options.push({ value: val.id, label: label })
                    }
                })
                setDetails({ details: true, options })
            }
        })

        return () => {
            isCancelled = true;
        }
    }, [])
    useEffect(() => {
        setSelected(selectedValue)
    }, [details, selectedValue])

    return (
        <Select
            placeholder={placeholder}
            styles={styles}
            value={details.options.find((obj) => obj.value === selected)}
            options={details.options}
            onChange={(e) => onInputChange(e?.value ?? '')}
            isClearable={isClearable}
            width='100%'
        />
    )
}

function mapStateToProps(state) {
    return {
        auth: state.Auth.userVerified,
    }
}

export default connect(mapStateToProps)(ClientSelect)
