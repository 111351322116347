import React, { useContext } from 'react'
import { Box, Flex } from 'rebass/styled-components'
import { ThemeContext } from 'styled-components'

const SearchResult = ({ result, handleSelect }) => {
    const theme = useContext(ThemeContext)
    return (
        <Flex onMouseDown={() => handleSelect(result)} fontSize='sm' sx={{
            borderWidth: '1px',
            borderStyle: 'solid',
            borderColor: 'whiteOff',
            borderTop: 0,
            borderLeft: 0,
            borderRight: 0,

            color: theme.colors.white,
            display: 'flex',
            padding:theme.space.xxs,
            width: '100%',
            ':hover': {
                color: theme.colors.salmon,
                cursor: 'pointer',
            }
        }}>
            <Box pr='xxs'>{result.title}</Box><Box>({result['characteristics.chassis.number'] || 'No Chassis'})</Box>
        </Flex>
    )
}

export default SearchResult
