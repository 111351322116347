import React, { useState, useEffect } from 'react'
import Select from 'react-select'
import { onSnapshot, collection, query, orderBy } from 'firebase/firestore'
import { db } from '../../system/firebase/index'

const SimpleDBSelect = ({ dispatch, placeholder, onSelectChange, collectionName, labelFieldName = "label", item, styles = false }) => {
    const [dropdown, setDropdown] = useState([])
    const [items, setItems] = useState([])
    const [currentItem, setItem] = useState(false)
    
    useEffect(() => {
        let isCancelled = false
        const q = query(collection(db, collectionName), orderBy(labelFieldName, 'asc'))
        onSnapshot(q, (snapshot) => {
            if (!isCancelled) {
                const output = []
                snapshot.forEach((doc) => {
                    output.push({ ...doc.data(), DocID: doc.id })
                })
                setItems(output)
            }
        })
        return () => {
            isCancelled = true
        }
    }, [collectionName, labelFieldName])
    
    useEffect(() => {
        const options = []
        if (items) {
            Object.entries(items).map(([key, val]) => {
                options.push({ value: val.DocID, label: val[labelFieldName] })
                return true
            })
            setDropdown(options)
        }
    }, [items, labelFieldName])

    useEffect(() => {
        setItem(dropdown.filter((option) => option.value === item )[0])
    }, [dropdown, item])

    const handleSelectChange = (newItem) => {
        setItem(newItem)
        onSelectChange(newItem)
    }

    return <Select
        placeholder={placeholder}
        styles={styles}
        options={dropdown}
        value={currentItem}
        onChange={(newItem) => handleSelectChange(newItem)}
    />
}
export default SimpleDBSelect
