import React, { useContext, useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { useForm } from 'react-hook-form'
import { navigate } from 'gatsby'

import { saveToggle, savingToggle } from '../../system/redux/reducers/savehandling'
import { setPageData, addPageData, resetPageData } from '../../system/redux/reducers/page'
import { confirmBoxToggle } from '../../system/redux/reducers/confirmbox'
import { resetPageBuilder, setPageCharacteristics } from '../../system/redux/reducers/pagebuilder'
import { doc, onSnapshot, Timestamp, collection, where, query, orderBy, limit } from 'firebase/firestore'
import { db } from '../../system/firebase/index'
import { addLead, archiveLead, unarchiveLead, updateLead } from '../../models/Leads'
import { getName } from '../../helpers/Clients'

import { Box, Button, Flex, Text, Heading, Link } from 'rebass/styled-components'
import { Link as GatsbyLink } from 'gatsby'
import Moment from 'react-moment'
import PageLoader from '../../components/loaders/PageLoader'
import Loading from '../../components/general/Loading'
import SaveButton from '../../components/rightSideBar/SaveButton'
import Item from '../../components/rightSideBar/Item'
import LeadSearchBar from '../../components/searchBar/LeadSearchBar'
import UserSelect from '../../components/selects/UserSelect'
import ClientSelect from '../../components/selects/ClientSelect'
import SimpleSelect from '../../components/selects/SimpleSelect'
import SimpleDBSelect from '../../components/selects/SimpleDBSelect'
import MakeSelect from '../../components/selects/MakeSelect'
import ModelSelect from '../../components/selects/ModelSelect'
import EditableField from '../../components/forms/EditableField'
import LeadsTable from '../../modules/leads/LeadsTable'
import styled, { ThemeContext } from 'styled-components'
// import NotesEditor from '../../modules/leads/NotesEditor'

import NotesTab from '../../modules/NotesTab'

// TODO: LATER: Could this schema actually be outlined in models/Leads.js? as Lead.js? and we use a class instaed?

const ChassisLink = styled(GatsbyLink)`
    color: ${(props) => props.theme.colors.greyDark};

    &:hover {
        color: ${(props) => props.theme.colors.red};
    }
`

const ClientLink = styled(GatsbyLink)`
    color: ${(props) => props.theme.colors.red};
    font-weight: 800;

    &:hover {
        color: ${(props) => props.theme.colors.greyDark};
    }
`

const ClientDetailsLink = styled(Link)`
    color: ${(props) => props.theme.colors.red};
    font-weight: 400;

    &:hover {
        color: ${(props) => props.theme.colors.greyDark};
    }
`

const FieldWrapper = ({ children, title, borderTop = true, borderBottom = false, borderLeft = true }) => {
    const theme = useContext(ThemeContext)

    const lightGreyBorder = {
        borderWidth: '1px',
        borderStyle: 'solid',
        borderColor: theme.colors.greyLight,
        borderTop: 0,
        borderLeft: 0,
        borderRight: 0,
        borderBottom: 0,
    }

    // const errorBorders = {
    //     borderColor: theme.colors.red,
    //     borderWidth: '2px',
    //     borderRight: 'auto',
    //     borderBottom: 'auto',
    // }

    const errorInnerBorders = {
        content: '""',
        position: 'absolute',
        top: '-1px',
        bottom: '-1px',
        right: '-1px',
        left: '-1px',
        border: `2px solid ${theme.colors.red}`,
        pointerEvents: 'none',
    }

    return (
        <Flex>
            <Flex alignItems='center' width={1/3} py={3} px={6} color="greyDark" fontSize="xl" fontWeight="bold" sx={{ ...lightGreyBorder, borderTop: borderTop ? 'auto' : 0, borderBottom: borderBottom ? 'auto' : 0, borderLeft: borderLeft ? 'auto' : 0 }}>{title}</Flex>
            <Flex width={2/3}>
                {React.Children.map(children, (child, index) => <Flex alignItems='center' flexGrow={index + 1} px={6} sx={{ position: 'relative', ...lightGreyBorder, borderTop: borderTop ? 'auto' : 0, borderBottom: borderBottom ? 'auto' : 0, borderLeft: 'auto', /*...(child?.props?.error ? errorBorders : {}),*/ '>*': { width: '100%' }, ':after': (child?.props?.error ? { ...errorInnerBorders } : {}) }}>{child}</Flex>)}
            </Flex>
        </Flex>
    )
}

// TODO:
//  [ ] refactor certain components out? especially those wth lots of useEffects / DB calls (eg. owner lookup)
//  [ ] maybe pull itemDetails out of viewData? do we use it for anything else? (knowing when the page is loaded, but otherwise a lot of deep nesting)

const formDefaultValues = {
    type: null,                 // string (required) - either "buying" or "selling"
    specialist: null,           // string (required) - users ID
    item: null,                 // string (required if not item) - collectionWebProfile ID - null if using model instead
    model: null,                // string (required if not model) - collectionCharacteristics -> Models ID - null if using item instead
    buyer: null,                // string (required if buying Lead) - clients ID - null if type = selling
    onContract: null,           // boolean (required if selling Lead) - null if type = buying
    guidePrice: null,           // integer (required)
    guidePriceCurrency: null,   // string (required) - currencies ID
    source: null,               // string (required?) - leadsSources ID
}

// NOTE: pageData is state.Page and is used for model data (to be saved / loaded), viewData is data needed just for display in this view
const LeadsEditView = ({ location, dispatch, auth, id, isSaving, pageData, pageModel }) => {
    const theme = useContext(ThemeContext)
    const { register, unregister, handleSubmit, setValue, formState: { errors }} = useForm({
        defaultValues: formDefaultValues,
        shouldUnregister: true
    })
    const [viewData, setViewData] = useState(false)
    const [itemPicker, setItemPicker] = useState(false)
    const [makesCharacteristicId, setMakesCharacteristicId] = useState(false)
    const [modelsCharacteristicId, setModelsCharacteristicId] = useState(false)
    const [makes, setMakes] = useState(false)
    const [models, setModels] = useState(false)

    // TODO: Could model and make details be loaded only if a model lead is chosen? (but cached to only load once, even if changed to exact match lead and back)

    // register form fields for validation
    useEffect(() => {
        register('type', { required: true })
        register('specialist', { required: true })
        register('item', { required: true })
        register('model', { required: true })
        register('buyer', { required: true })
        register('onContract', { validate: (value) => value === true || value === false })
        register('guidePrice', { required: true })
        register('guidePriceCurrency', { required: true })
        register('source', { required: true })
    }, [register])

    // load make and model characteristic ids
    useEffect(() => {
        let isCancelled = false
        
        const q = query(collection(db, 'collectionCharacteristics'), where('slug', 'in', ['makes', 'models']))
        onSnapshot(q, (snapshot) => {
            if (!isCancelled) {
                snapshot.forEach((doc) => {
                    const docData = doc.data()
                    if(docData.slug === 'makes') setMakesCharacteristicId(doc.id)
                    if(docData.slug === 'models') setModelsCharacteristicId(doc.id)
                })
            }
        })

        return () => {
            isCancelled = true;
        }
    }, [])

    // load make details
    useEffect(() => {
        let isCancelled = false

        if(makesCharacteristicId) {
            const q = query(collection(db, 'collectionCharacteristics', makesCharacteristicId, 'list'))
            onSnapshot(q, (snapshot) => {
                if (!isCancelled) {
                    const items = {}
                    snapshot.forEach((doc) => {
                        const docData = doc.data()
                        items[doc.id] = docData
                    })
                    setMakes(items)
                }
            })
        }

        return () => {
            isCancelled = true;
        }
    }, [makesCharacteristicId])

    // load model details
    useEffect(() => {
        let isCancelled = false

        if(modelsCharacteristicId) {
            const q = query(collection(db, 'collectionCharacteristics', modelsCharacteristicId, 'list'))
            onSnapshot(q, (snapshot) => {
                if (!isCancelled) {
                    const items = {}
                    snapshot.forEach((doc) => {
                        const docData = doc.data()
                        items[doc.id] = docData
                    })
                    setModels(items)
                }
            })
        }

        return () => {
            isCancelled = true;
        }
    }, [modelsCharacteristicId])

    // on first mount
    useEffect(() => {
        dispatch(resetPageData())
        dispatch(resetPageBuilder())
        setViewData({})
        
        // we may have been passed data via location.state
        if (location?.state?.type) {
            updatePageData({ type: location.state.type })
        }
        if (location?.state?.profileId) {
            updatePageData({ item: location.state.profileId, model: null })
        }
    }, [])

    // on id change
    useEffect(() => {
        let isCancelled = false

        if(id) {
            dispatch(resetPageData())
            dispatch(resetPageBuilder())
            setViewData({})

            onSnapshot(doc(db, 'leads', id), (doc) => {
                if (!isCancelled) {
                    const data = doc.data()
                    if (data) {
                        data.id = doc.id
                        dispatch(setPageData( JSON.stringify(data) ))

                        // setValues for form validation
                        Object.keys(formDefaultValues).forEach((key) => {
                            setValue(key, data[key])
                        })
                    } else {
                        // Failsafe for if Lead gets deleted
                        dispatch(setPageData( JSON.stringify({}) ))
                    }
                }
            })
        }

        return () => {
            isCancelled = true
            dispatch(resetPageData())
        }
    }, [id])

    // if the Lead's item changes, load details for it
    useEffect(() => {
        let isCancelled = false

        if(pageData.item) {
            register('model', { required: false })
            onSnapshot(doc(db, 'collectionWebProfiles', pageData.item), (doc) => {
                if (!isCancelled) {
                    const data = doc.data()
                    if (data) {
                        updateViewData({ itemDetails: { ...data }})
                    } else {
                        // Failsafe for if Collection gets deleted
                        updateViewData({ itemDetails: false })
                    }
                }
            })
        } else {
            register('model', { required: true })
        }

        return () => {
            isCancelled = true
        }
    }, [pageData.item])

    // TODO: Is owner based on collectionHistory (currently used), or collectionSaleDetails?

    // if the Lead's item changes, load other details for it (eg. collectionHistory)
    useEffect(() => {
        let isCancelled = false

        if(viewData.itemDetails?.item_id) {
            const q = query(collection(db, 'collectionHistory'), where('sku', '==', viewData.itemDetails.item_id), orderBy('date_added','desc'), limit(1))
            onSnapshot(q, (snapshot) => {
                if (!isCancelled) {
                    snapshot.forEach((doc) => {
                        const docData = doc.data()
                        const newDetails = { ...viewData.itemDetails, history: { id: doc.id, ...docData }}
                        updateViewData({ itemDetails: newDetails })
                    })
                }
            })
        }

        return () => {
            isCancelled = true
        }
    }, [viewData.itemDetails?.item_id])

    // if the Lead's item's collectionHistory changes, load other details for it (eg. owner client)
    useEffect(() => {
        let isCancelled = false

        if(viewData.itemDetails?.history?.owner) {
            onSnapshot(doc(db, 'clients', viewData.itemDetails.history.owner), (doc) => {
                if (!isCancelled) {
                    const docData = doc.data()
                    if (docData) {
                        updateViewData({ ownerDetails: { id: doc.id, ...docData }})
                    } else {
                        // Failsafe for if Collection gets deleted
                        updateViewData({ ownerDetails: false })
                    }
                }
            })
        }

        return () => {
            isCancelled = true
        }
    }, [viewData.itemDetails?.history?.id])

    // if the Lead's model changes, create the itemDetails (currently just a title)
    useEffect(() => {
        if(pageData.model) {
            unregister('item')

            let titleElements = []
            if(models[pageData.model]?.make && makes[models[pageData.model]?.make]?.title) titleElements.push(makes[models[pageData.model]?.make]?.title)
            if(models[pageData.model]?.title) titleElements.push(models[pageData.model]?.title)

            updateViewData({ itemDetails: { title: titleElements.join(' ') }})
        } else {
            register('item', { required: true })
        }
    }, [pageData.model, makes, models])

    // TODO: Currently we're only storing model, but should we store make (rather than grabbing from DB)?
    useEffect(() => {
        if(pageModel) {
            setItemPicker(false)
            updatePageData({ item: null, model: pageModel })
            dispatch(setPageCharacteristics(JSON.stringify({ make: null, model: null }))) // TODO: PageCharacteristics update required for make/model select - could be removed if MakeSelect and ModelSelect are decoupled from PageCharacteristics
        }
    }, [pageModel])

    // if the Lead type changes, apply certain restrictions (buying: remove onContract, selling: remove buyer and model lead)
    useEffect(() => {
        if(pageData.type) {
            if (pageData.type === 'buying') {
                register('buyer', { required: true })
                unregister('onContract')

                updatePageData({ onContract: null })
            } else if (pageData.type === 'selling') {
                unregister('buyer')
                register('onContract', { validate: (value) => value === true || value === false })

                updatePageData({ buyer: null, model: null })
            }
        }
    }, [pageData.type])

    // if the buyer changes, add (or remove) from buyerDetails
    useEffect(() => {
        let isCancelled = false

        if(pageData.buyer) {
            onSnapshot(doc(db, 'clients', pageData.buyer), (doc) => {
                if (!isCancelled) {
                    const docData = doc.data()
                    if (docData) {
                        updateViewData({ buyerDetails:  { id: doc.id, ...docData }})
                    } else {
                        // Failsafe for if Collection gets deleted
                        updateViewData({ buyerDetails: false })
                    }
                }
            })
        } else {
            updateViewData({ buyerDetails: false })
        }

        return () => {
            isCancelled = true
        }
    }, [pageData.buyer])

    const updatePageData = (data, toggleSave = true) => {
        // update validation values
        for (const [key, value] of Object.entries(data)) {
            setValue(key, value, {
                shouldValidate: true,
                shouldDirty: true
            })
        }

        // update pageData
        const detailsData = JSON.stringify(data)
        dispatch(addPageData(detailsData))
        if(toggleSave) dispatch(saveToggle(true))
    }

    const updateViewData = (data) => {
        setViewData((state) => ({ ...state, ...data }))
    }

    const handleInputChange = (name, event) => {
        updatePageData({ [name]: event.target.value })
    }

    const handleSelectChange = (name, item) => {
        updatePageData({ [name]: item.value })
    }

    const handleSearchResultSelect = (result) => {
        setItemPicker(false)
        updatePageData({ item: result.objectID, model: null }) // TODO: This is currently the collectionWebProfile id, not collection id
    }

    const toggleArchived = (event) => {
        event.preventDefault()

        dispatch(savingToggle(true))
        dispatch(saveToggle(false))

        if ( pageData.id ) {
            if(pageData.date_archived) {
                unarchiveLead(pageData.id).then((data) => {
                    updatePageData({ date_archived: null }, false)
                    dispatch(savingToggle(false))
                })
            } else {
                archiveLead(pageData.id).then((data) => {
                    updatePageData({ date_archived: { seconds: data.data['_writeTime']['_seconds'], nanoseconds: data.data['_writeTime']['_nanoseconds'] }}, false)
                    dispatch(savingToggle(false))
                })
            }
        }
    }

    const handleDelete = (event) => {
        event.preventDefault()
        
        const confirmData = JSON.stringify({ isOpen: true, id: pageData.id, modalType: 'DELETE_LEAD', collection: null })
        dispatch(confirmBoxToggle(confirmData))
    }

    const onSubmit = async () => {
        dispatch(savingToggle(true))
        dispatch(saveToggle(false))

        const updateData = { ...pageData }

        if ( pageData.id ) {
            // This is an update
            const docID = pageData.id
            delete updateData.id
            delete updateData.date_added

            updateData.edited_by = auth.uid

            updateLead(docID, updateData).then(() => {
                dispatch(savingToggle(false))
            })
        } else {
            // This is an add

            updateData.added_by = auth.uid
            updateData.edited_by = auth.uid

            addLead(updateData).then((docRef) => {
                dispatch(savingToggle(false))
                navigate(`/leads/edit/${docRef.data}`)
            })
        }
    }

    const lightGreyBorder = {
        borderWidth: '1px',
        borderStyle: 'solid',
        borderColor: theme.colors.greyLight,
        borderTop: 0,
        borderLeft: 0,
        borderRight: 0,
        borderBottom: 0,
    }

    const errorInnerBorders = {
        content: '""',
        position: 'absolute',
        top: '-1px',
        bottom: '-1px',
        right: '-1px',
        left: '-1px',
        border: `2px solid ${theme.colors.red}`,
        pointerEvents: 'none',
    }

    const selectStyles =  {
        control: (provided) => ({
            ...provided,
            color: theme.colors.darkGrey,
            border: 0,
            boxShadow: 'none',
            '&:hover': {
                border: 0,
                boxShadow: 'none',
                cursor: 'pointer',
            },
            '&:focus': {
                border: 0,
                boxShadow: 'none',
            },
        }),
        singleValue: (provided) => ({
            ...provided,
            color: theme.colors.darkGrey,
        }),
        option: (provided, state) => ({
            ...provided,
            color: state.isSelected ? 'white' : theme.colors.darkGrey,
            backgroundColor: state.isSelected ? theme.colors.red : 'white',
            cursor: 'pointer',
            '&:hover': {
                backgroundColor: state.isSelected ? theme.colors.red : theme.colors.redOff,
            },
        }),
        indicatorSeparator: () => ({
            display: 'none',
        }),
        dropdownIndicator: (provided) => ({
            ...provided,
            color: theme.colors.darkGrey,
            '&:hover': {
                color: theme.colors.darkGrey,
            },
        }),
        menu: (provided) => ({
            ...provided,
            zIndex: 20,
        })
    }

    const getEnquiryAgeColour = (dateAdded) => {
        const enquiryAgeMs = new Date() - new Timestamp(dateAdded.seconds, dateAdded.nanoseconds).toDate()

        if(enquiryAgeMs >= 60 * 24 * 60 * 60 * 1000) return 'red'
        else if(enquiryAgeMs >= 30 * 24 * 60 * 60 * 1000) return 'orange'
        else return 'greyDark'
    }
    return (
        !viewData ?
            <Loading />
            :
            <Box as='form' id="hook-form" onSubmit={handleSubmit(() => onSubmit())}>
                <Box>
                    <Flex>
                        <Flex flexGrow={1} flex={1} pr='xs' flexDirection='column'>
                            <Box variant="card">
                                <Box px={5} py={4}>
                                    <Flex alignItems='center' justifyContent='space-between'>
                                        <Heading variant="h4" as="h4" color='greyMedium'>{id ? "Edit" : "Add"} Lead</Heading>
                                        <Flex>
                                            {/* TODO: SaveButton is always "Ready to Save" on first edit load - this is from checking and clearing the buyer field - can fix this by only clearing if buyer != null, but will this field then be empty in the db? - BETTER fix would be to maintain a Lead model for add/update that nulls missing fields - BEST fix would be to check diff of pageData before and re and after update and only saveToggle(true) if it's different  */}
                                            <SaveButton form="hook-form"/>
                                            { id ? <Button ml={2} bg={'grey'} sx={{ borderColor:'grey' }} onClick={toggleArchived}>{pageData.date_archived ? 'Unarchive' : 'Archive' }</Button> : null }
                                            { id ? <Button ml={2} bg={'grey'} sx={{ borderColor:'grey' }} onClick={handleDelete}>Delete</Button> : null }
                                        </Flex>
                                    </Flex>
                                </Box>
                                
                                <FieldWrapper title="Lead Type*" borderBottom borderLeft={false}>
                                    <SimpleSelect placeholder='Select Lead Type...' styles={selectStyles} item={pageData.type || null} onSelectChange={(item) => handleSelectChange('type', item)} options={[{ label: "Buying", value: "buying" }, { label: "Selling", value: "selling" }]} error={errors.type} />
                                </FieldWrapper>
                                
                                {
                                    !pageData.type || ((itemPicker !== "edit") && (itemPicker || pageData.item || pageData.model)) ? 
                                        null
                                        :
                                        <Box py='sm' px='md' sx={{ ...lightGreyBorder, borderBottom: 'auto', position: 'relative', ':after': (errors.item || errors.model ? { ...errorInnerBorders } : {}) }}>
                                            <Flex>
                                                {
                                                    pageData.type === "buying" ?
                                                        <Button bg={'red'} mr={2} sx={{ flexGrow: 1, borderColor:'red' }} onClick={() => setItemPicker({ type: 'model' })}>Model Lead</Button>
                                                        : null
                                                }
                                                <Button bg={'red'} sx={{ flexGrow: 1, borderColor:'red' }} onClick={() => setItemPicker({ type: 'exact' })}>Exact Match Lead</Button>
                                            </Flex>
                                            { itemPicker === "edit" ? <Button variant='primaryTiny' mt='sm' onClick={(event) => {event.preventDefault(); setItemPicker(false)}}>Cancel</Button> : null }
                                        </Box>
                                }
                                
                                {
                                    itemPicker?.type === 'model' ?
                                        <Box sx={{ ...lightGreyBorder, borderBottom: 'auto', position: 'relative', ':after': (errors.item || errors.model ? { ...errorInnerBorders } : {}) }}>  
                                            <FieldWrapper title="Make &amp; Model*" borderTop={false} borderBottom borderLeft={false}>
                                                <MakeSelect placeholder='Select Make...' />
                                                <ModelSelect placeholder='Select Model...' error={errors.model} />
                                            </FieldWrapper>
                                            <Button variant='primaryTiny' my='sm' ml='md' onClick={(event) => {event.preventDefault(); setItemPicker(false)}}>Cancel</Button>
                                        </Box>
                                        : null
                                }
                                
                                {
                                    itemPicker?.type === 'exact' ?
                                        <Box py='sm' px='md' sx={{ ...lightGreyBorder, borderBottom: 'auto', position: 'relative', ':after': (errors.item || errors.model ? { ...errorInnerBorders } : {}) }}>
                                            <LeadSearchBar resultSelectCallback={handleSearchResultSelect} />
                                            <Button variant='primaryTiny' mt='sm' onClick={(event) => {event.preventDefault(); setItemPicker(false)}}>Cancel</Button>
                                        </Box>
                                        : null
                                }

                                {
                                    pageData.type ?
                                        <Flex mx='md' my='md' sx={{ ...lightGreyBorder, borderBottom: 'auto' }}>
                                            <Flex width={1/3} mr='sm' flexDirection='column' justifyContent='space-between' >
                                                <Box>
                                                    <Heading variant="h2" as="h2" fontWeight="bold" color="red">{viewData.itemDetails?.title || "Vehicle Title"}</Heading>

                                                    {
                                                        viewData.itemDetails ?
                                                            <Box>
                                                                {
                                                                    pageData.item 
                                                                        ? <Text my={2} fontSize="xxl" fontWeight="bold" color="greyDark">Chassis: <ChassisLink to={`/collection/edit/${pageData.item}`}>{viewData.itemDetails?.characteristics?.chassis?.number || "Unknown"}</ChassisLink></Text>
                                                                        : <Text my={2} fontSize="xxl" fontWeight="bold" color="greyDark">Chassis: Model Lead</Text>
                                                                }
                                                            </Box>
                                                            : null
                                                    }

                                                    { (pageData.item || pageData.model) && !itemPicker ?
                                                        <Button my={2} variant='primaryTiny' onClick={(event) => {event.preventDefault(); setItemPicker("edit")}}>Edit</Button>
                                                        : null
                                                    }
                                                </Box>

                                                <Box>
                                                    {
                                                        viewData.itemDetails ?
                                                            <Box py={2} sx={{ ...lightGreyBorder, borderBottom: 'auto' }}>
                                                                <Text fontSize="xxl" fontWeight="bold" color="greyDark">Current Owner: {pageData.model ? "Model Lead" : ((viewData.ownerDetails) ? <ClientLink to={`/clients/edit/${viewData.ownerDetails.id}`}>{getName(viewData.ownerDetails.first_name, viewData.ownerDetails.last_name, viewData.ownerDetails.company)}</ClientLink> : "Unknown")}</Text>
                                                                {
                                                                    viewData.ownerDetails && (viewData.ownerDetails?.contact?.mobile || viewData.ownerDetails?.contact?.email) ? 
                                                                        <Flex mt={1}>
                                                                            { viewData.ownerDetails?.contact?.mobile ? <Text color="greyDark" mr={1}>M: <ClientDetailsLink href={`tel:${viewData.ownerDetails.contact.mobile}`}>{viewData.ownerDetails.contact.mobile ?? ''}</ClientDetailsLink></Text> : null }
                                                                            { viewData.ownerDetails?.contact?.email ? <Text color="greyDark">E: <ClientDetailsLink href={`mailto:${viewData.ownerDetails.contact.email}`}>{viewData.ownerDetails.contact.email ?? ''}</ClientDetailsLink></Text> : null }
                                                                        </Flex> 
                                                                        : null
                                                                }
                                                            </Box>
                                                            : null
                                                    }

                                                    {
                                                        pageData.buyer && viewData.itemDetails ?
                                                            <Box py={2}>
                                                                <Text fontSize="xxl" fontWeight="bold" color="greyDark">Buyer: { viewData.buyerDetails ? <ClientLink to={`/clients/edit/${viewData.buyerDetails.id}`}>{getName(viewData.buyerDetails.first_name, viewData.buyerDetails.last_name, viewData.buyerDetails.company)}</ClientLink> : "Unknown" }</Text>
                                                                {
                                                                    viewData.buyerDetails && (viewData.buyerDetails?.contact?.mobile || viewData.buyerDetails?.contact?.email) ? 
                                                                        <Flex mt={1}>
                                                                            { viewData.buyerDetails?.contact?.mobile ? <Text color="greyDark" mr={1}>M: <ClientDetailsLink href={`tel:${viewData.buyerDetails.contact.mobile}`}>{viewData.buyerDetails.contact.mobile ?? ''}</ClientDetailsLink></Text> : null }
                                                                            { viewData.buyerDetails?.contact?.email ? <Text color="greyDark">E: <ClientDetailsLink href={`mailto:${viewData.buyerDetails.contact.email}`}>{viewData.buyerDetails.contact.email ?? ''}</ClientDetailsLink></Text> : null }
                                                                        </Flex> 
                                                                        : null
                                                                }
                                                            </Box>
                                                            : null
                                                    }
                                                </Box>
                                            </Flex>

                                            <Flex width={2/3} ml='sm' alignItems='end'>
                                                <Box width='100%'>
                                                    <FieldWrapper title="Specialist*">
                                                        <UserSelect placeholder='Select Specialist...' styles={selectStyles} item={pageData.specialist || null} onSelectChange={(item) => handleSelectChange('specialist', item)} specialist={true} error={errors.specialist} />
                                                    </FieldWrapper>
                                                    <FieldWrapper title="Source*">
                                                        <SimpleDBSelect placeholder='Select Source...' styles={selectStyles} item={pageData.source || null} onSelectChange={(item) => handleSelectChange('source', item)} collectionName='leadsSources' error={errors.source} />
                                                    </FieldWrapper>

                                                    {
                                                        pageData.type === 'buying' ?
                                                            <FieldWrapper title="Buyer*">
                                                                <ClientSelect placeholder='Select Buyer...' styles={selectStyles} onInputChange={(item) => handleSelectChange('buyer', { value: item })} active={true} selectedValue={pageData.buyer} error={errors.buyer} />
                                                            </FieldWrapper>
                                                            : null
                                                    }

                                                    {
                                                        pageData.type === 'selling' ?
                                                            <FieldWrapper title="On Sale Contract?*">
                                                                <SimpleSelect placeholder='Select...' styles={selectStyles} item={pageData.onContract ?? null} onSelectChange={(item) => handleSelectChange('onContract', item)} options={[{ label: "Yes", value: true }, { label: "No", value: false }]} error={errors.onContract} />
                                                            </FieldWrapper>
                                                            : null
                                                    }

                                                    <FieldWrapper title="Guide Price*">
                                                        <SimpleDBSelect placeholder='Currency...' styles={selectStyles} item={pageData.guidePriceCurrency || null} onSelectChange={(item) => handleSelectChange('guidePriceCurrency', item)} collectionName='currencies' labelFieldName='symbol' error={errors.guidePriceCurrency} />
                                                        <EditableField variant="contentEditableInput" pl={0} name="guidePrice" type='text' value={pageData.guidePrice || ''} onChange={(event) => handleInputChange('guidePrice', event)} error={errors.guidePrice} />
                                                    </FieldWrapper>
                                                </Box>
                                            </Flex>
                                        </Flex>
                                        : null
                                }

                                {
                                    pageData.date_added ?
                                        <Flex mt={-3} mb={-1} mx='md'>
                                            <Text color="greyDark" fontSize="xl" mr={2}>Date Created: <Moment format="DD MMM YYYY" date={new Timestamp(pageData.date_added.seconds, pageData.date_added.nanoseconds).toDate()} /></Text>
                                            <Text color="greyDark" fontSize="xl">Enquiry Age: <Text as='span' color={getEnquiryAgeColour(pageData.date_added)}><Moment fromNow ago date={new Timestamp(pageData.date_added.seconds, pageData.date_added.nanoseconds).toDate()} /></Text></Text>
                                        </Flex>
                                        : null
                                }

                                {
                                    id ?
                                        <Box mx='md' my='md'>
                                            <NotesTab type='lead' />
                                        </Box>
                                        : null
                                }

                                {
                                    (pageData?.item && viewData?.itemDetails?.characteristics) || pageData?.model ? // FIXME: Really need to find a better way of determining when this is ready to load in - currently a page starts with viewDetails: {title: ''} and pageData: {item: null, model: A_MODEL_ID}, then goes through 3-4 changes (sending new queries to Algolia), before settling to pageData: {item: AN_ITEM_ID, model: null} and viewDetails: { ...ACTUAL_DETAILS }
                                        <Box mx='md' my='md'>
                                            <Heading as='h4' variant='h4' color='greyMedium' mb='sm'>Matches</Heading>
                                            <LeadsTable leadModelId={ pageData.item ? viewData.itemDetails.characteristics.model : pageData.model }></LeadsTable>
                                        </Box>
                                        : null
                                }
                            </Box>
                        </Flex>
                    </Flex>
                </Box>
                {
                    isSaving ?
                        <PageLoader />
                        : null
                }
            </Box>
    );
}

function mapStateToProps(state) {
    return {
        pageData: state.Page.data,
        pageModel: state.Pagebuilder.extras?.characteristics?.model,
        isSaving: state.Savehandling.isSaving,
        auth: state.Auth.userVerified,
    }
}
export default connect(mapStateToProps)(LeadsEditView)