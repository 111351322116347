/* SCHEMA:
new LeadSearch({
    // From Lead:
    date_added: null,               // timestamp (required)
    date_modified: null,            // timestamp (required)
    added_by: null,                 // string (required) - users ID
    edited_by: null,                // string (required) - users ID

    date_archived: null,            // timestamp - date if archived, otherwise null
    
    type: null,                     // string (required) - either "buying" or "selling"

    specialist: null,               // string (required) - users ID

    item: null,                     // string (required if not item) - collectionWebProfile ID - null if using model instead
    model: null,                    // string (required if not model) - collectionCharacteristics -> Models ID - null if using item instead

    buyer: null,                    // string (required if buying Lead) - clients ID - null if type = selling
    
    onContract: null,               // boolean (required if selling Lead) - null if type = buying
    
    guidePrice: null,               // integer (required)
    guidePriceCurrency: null,       // string (required) - currencies ID
  
    source: null,                   // string (required?) - leadsSources ID

    notes: [],                      // array of objects

    // Extra Search fields:

    // matching
    // model: null,                 // string - collectionCharacteristics -> models ID
    
    // filtering
    isExact: null,                  // boolean
    isArchived: null,               // boolean
    owner: null,                    // string - clients ID

    // searching / displaying
    title: null,                    // string - item title / make + model
    chassis: null,                  // string
    buyerName: null,                // string
    ownerName: null,                // string
    specialistName: null,           // string
    guidePriceCurrencySymbol: null, // string
    sourceLabel: null,              // string
    firstNote: null,                // string
})
*/

export const addLeadsSearchBatch = async (data) => {
    if (typeof window !== 'undefined') {
        const { firebaseFunctionCall } = await import('../system/firebase/useFunctions')
        return firebaseFunctionCall('addLeadsSearchBatch')(data)
    }
    return null
}