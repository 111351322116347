import React from 'react'
import { Router } from '@reach/router'
import { withPrefix } from 'gatsby'
import Pointer from '../views/pointer'
import { LeadsSection } from '../system/settings/pageData'
import LeadsView from '../views/leads/index'
import LeadsEditView from '../views/leads/modify'
import LeadsTransferView from '../views/leads/transfer'

const PageInfo = {
    parent: LeadsSection.section,
    icon: LeadsSection.icon,
}

const LeadsRoutes = ({ menuItems }) => {
    return (
        <Router>
            <Pointer PageInfo={{ ...PageInfo, page: 'All Leads' }} Component={LeadsView} startingTab={'all'} minimumRole={LeadsSection.minimumRole} path={withPrefix('/leads')} />
            <Pointer PageInfo={{ ...PageInfo, page: 'Add New' }} Component={LeadsEditView} minimumRole={LeadsSection.minimumRole} path={withPrefix('/leads/add')} />
            <Pointer PageInfo={{ ...PageInfo, page: 'Edit' }} Component={LeadsEditView} minimumRole={LeadsSection.minimumRole} path={withPrefix('/leads/edit/:id')} />
            <Pointer PageInfo={{ ...PageInfo, page: 'Buying' }} Component={LeadsView} startingTab={'buying'} minimumRole={LeadsSection.minimumRole} path={withPrefix('/leads/buying')} />
            <Pointer PageInfo={{ ...PageInfo, page: 'Selling' }} Component={LeadsView} startingTab={'selling'} minimumRole={LeadsSection.minimumRole} path={withPrefix('/leads/selling')} />
            <Pointer PageInfo={{ ...PageInfo, page: 'Matches' }} Component={LeadsView} startingTab={'matches'} minimumRole={LeadsSection.minimumRole} path={withPrefix('/leads/matches')} />
            <Pointer PageInfo={{ ...PageInfo, page: 'Archived' }} Component={LeadsView} startingTab={'archived'} minimumRole={LeadsSection.minimumRole} path={withPrefix('/leads/archived')} />

            <Pointer PageInfo={{ ...PageInfo, page: 'Transfer' }} Component={LeadsTransferView} minimumRole={LeadsSection.minimumRole} path={withPrefix('/leads/transfer')} />
        </Router>
    )
}

export default LeadsRoutes
