import React, { useEffect, useState } from 'react'
import { Box, Heading, Link } from 'rebass/styled-components'
import { collection, getDocs, limit, orderBy, query, startAfter, where } from 'firebase/firestore'
import { db } from '../../system/firebase/index'
import { getName } from '../../helpers/Clients'
import { addLeadsSearchBatch } from '../../models/LeadsSearch'

async function getCollection(collectionName, orderByField = false, keyField = false, subCollectionName = false) {
    console.log(`Loading ${collectionName}${subCollectionName ? `/${subCollectionName}` : ''}...`)
        
    let subCollectionId = false
    if (subCollectionName) {
        const qSubCollection = query(collection(db, collectionName), where('slug', '==', subCollectionName))
        const qSubCollectionSnapshot = await getDocs(qSubCollection)
        qSubCollectionSnapshot.forEach((doc) => {
            subCollectionId = doc.id
        })
    }

    const collectionItems = {}
    const collectionParams = subCollectionId ? collection(db, collectionName, subCollectionId, 'list') : collection(db, collectionName)
    const orderParams = orderByField ? [orderBy(orderByField, 'desc')] : []
    const q = query(collectionParams, ...orderParams)
    const qSnapshot = await getDocs(q)
    qSnapshot.forEach((doc) => {
        const docData = doc.data()
        const key = keyField ? docData[keyField] : doc.id
        collectionItems[key] = { ...docData, id: doc.id }
    })

    console.log(`...loaded ${collectionName}${subCollectionName ? `/${subCollectionName}` : ''}.`)
    return collectionItems
}

const getVehicleName = (lead, collectionWebProfiles, makes, models) => {
    if(lead.item) {
        return collectionWebProfiles[lead.item]?.title ? collectionWebProfiles[lead.item]?.title.trim() : null
    } else if (lead.model) {
        const modelTitleElements = []
        if(models[lead.model]?.make && makes[models[lead.model]?.make]?.title) modelTitleElements.push(makes[models[lead.model]?.make]?.title)
        if(models[lead.model]?.title) modelTitleElements.push(models[lead.model]?.title)
        return modelTitleElements.join(' ').trim()
    } else {
        return null 
    }
}

async function transferFromLeadsToLeadsSearch() {
    // get required linked collections
    const [
        makes,
        models,
        collectionWebProfiles, 
        collectionHistory,
        clients,
        users,
        currencies,
        leadsSources,
    ] = await Promise.all([
        getCollection('collectionCharacteristics', 'date_added', false, 'makes'),
        getCollection('collectionCharacteristics', 'date_added', false, 'models'),
        getCollection('collectionWebProfiles', 'date_added'), 
        getCollection('collectionHistory', 'date_added', 'sku'),
        getCollection('clients', 'date_added'),
        getCollection('users'),
        getCollection('currencies'),
        getCollection('leadsSources'),
    ])

    // get batches of leads
    const batchLimit = 500
    let lastVisible = false

    while(lastVisible || lastVisible === false) {
        const constraints = [orderBy('date_added', 'desc'), limit(batchLimit)]
        if (lastVisible) constraints.push(startAfter(lastVisible))
        const q = query(collection(db, 'leads'), ...constraints)
        const qSnapshot = await getDocs(q)

        // reset lastVisible
        lastVisible = null

        // denormalise
        const denormalisedLeads = []
        qSnapshot.forEach((doc) => {
            const lead = doc.data()

            const owner = lead.item ? (collectionHistory[collectionWebProfiles[lead.item]?.item_id]?.owner ?? false) : false

            const denormalisedLead = { 
                id: doc.id, 
                content: {
                    ...lead,

                    // matching
                    model: lead.model ? lead.model : (collectionWebProfiles[lead.item]?.characteristics?.model ?? false), // FIXME: alert falses
                    
                    // filtering
                    isExact: lead.item ? true : false,
                    isArchived: lead.date_archived ? true : false,
                    owner, // FIXME: alert falses (where lead.item)

                    // searching / displaying
                    title: getVehicleName(lead, collectionWebProfiles, makes, models), // FIXME: alert falses
                    chassis: lead.item ? (collectionWebProfiles[lead.item]?.characteristics?.chassis?.number ?? false) : false, // FIXME: alert falses
                    buyerName: lead.buyer && clients[lead.buyer] ? getName(clients[lead.buyer].first_name, clients[lead.buyer].last_name, clients[lead.buyer].company) : false, // FIXME: alert falses
                    ownerName: owner ? getName(clients[owner].first_name, clients[owner].last_name, clients[owner].company) : false, // FIXME: alert falses
                    specialistName: lead.specialist ? `${users[lead.specialist]?.first_name} ${users[lead.specialist]?.last_name}`.trim() : false, // FIXME: alert falses
                    guidePriceCurrencySymbol: lead.guidePriceCurrency ? (currencies[lead.guidePriceCurrency]?.symbol ?? false) : false, // FIXME: alert falses
                    sourceLabel: lead.source ? (leadsSources[lead.source]?.label ?? false) : false, // FIXME: alert falses
                    firstNote: lead.notes ? (lead.notes[0]?.note ?? false) : false, // alert falses
                },
            }

            // convert any nulls to falses (for Algolia)
            for (const [key, value] of Object.entries(denormalisedLead.content)) {
                denormalisedLead.content[key] = value === null ? false : value
            }

            denormalisedLeads.push(denormalisedLead)

            lastVisible = doc
        })
        
        // save to leadsSearch
        if(denormalisedLeads.length) {
            console.log(`Saving ${denormalisedLeads.length} leadsSearch...`)
            console.log(denormalisedLeads)

            await addLeadsSearchBatch(denormalisedLeads)
            console.log('...saved.')
        }
    }

    console.log('COMPLETE')
}

const LeadsTransferView = ({ dispatch }) => {
    return (
        <Box variant="card" mt="sm">
            <Box p="md" sx={{
                borderWidth: '1px',
                borderStyle: 'solid',
                borderColor: 'greyLight',
                borderTop: 0,
                borderLeft: 0,
                borderRight: 0,
            }}>
                <Box>
                    <Heading as="h2" variant="h4" color="greyMedium">Transfer Leads</Heading>
                </Box>
            </Box>

            <Box p="md">
                <Link sx={{ cursor: 'pointer' }} onClick={transferFromLeadsToLeadsSearch}>transferFromLeadsToLeadsSearch</Link>

                <Box mt="md" fontStyle="italic">Open the console to view progress.</Box>
            </Box>
        </Box>
    )
}

export default LeadsTransferView
