import React, { useContext, useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { Box, Button } from 'rebass/styled-components'
import LeadsTable from '../../modules/leads/LeadsTable'
import { ThemeContext } from 'styled-components'
import { navigate } from 'gatsby'
import LeadsSearchBar from '../../modules/leads/LeadsSearchBar'

const LeadsView = ({ startingTab = 'all' }) => {
    const theme = useContext(ThemeContext)
    const [searchTerm, setSearchTerm] = useState('')

    const addNew = () => {
        navigate(`/leads/add/`)
    }

    return (
        <Box variant='card'>
            <Box
                display='flex'
                py='sm'
                px={3}
                sx={{ borderBottom: `2px solid ${theme.colors.greyLight}` }}
            >
                <LeadsSearchBar setSearchTerm={setSearchTerm} />
                <Box display='flex' justifyContent='flex-end'>
                    <Button
                        bg={theme.colors.red}
                        sx={{ borderColor: theme.colors.red }}
                        onClick={addNew}
                    >
                        Add Lead
                    </Button>
                </Box>
            </Box>
            <Box py='sm' px={6}>
                <LeadsTable
                    startingTab={startingTab}
                    searchTerm={searchTerm}
                ></LeadsTable>
            </Box>
        </Box>
    )
}

function mapStateToProps(state) {
    return {}
}

export default connect(mapStateToProps)(LeadsView)
